import styled, { css } from 'styled-components';
import { ImageUrl } from '../../utils/constants';
import { StyledTab } from '../Settings/styles';

const Layout = styled.div`
  width: 100%;
  padding: 25px;
  margin: auto;
  
  @media (max-width: 500px) {
    padding: 30px 0;
  }

  ${({ isArticlePage }) => isArticlePage && css`
    ul.nav.nav-tabs {
      display: none !important;
    }
  `}
`;

const HeaderContainer = styled.div`
  width: 100%;
  padding: 0 32px;
  > span {
    font-size: 24px;
    letter-spacing: 1px;
    color: rgb(51,51,51);
    @media (max-width: 500px) {
      margin-bottom: 40px;
      float: left;
      width: 100%;
    }
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
  ${({ isArticlePage }) => isArticlePage && css`
    display: none;

    @media (max-width: 500px) {
      display: none;
    }
  `}
`;

const CustomTabs = styled(StyledTab)`
    margin-top: 25px;
    width: 67.1%;
    padding: 20px 20px 1px;
    margin-top: 25px;
    background-color: white;
    border-radius: 6px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: space-around;
    padding: 20px 20px 1px 20px;
    margin-top: 25px;
    background-color: white;
    border-radius: 6px;
    position: ${({position}) => position === 1 ? '' : 'relative'};
    bottom: 150px;
    .nav-item > .active {
      border-bottom: 3px solid rgb(13, 66, 112);
      width: 100%;
    }
    li {
      border: none;
      width: 25%;
      display: flex;
      justify-content: space-around;
    }
    li > .nav-link {
      border: 0px;
      background-color: none !important;
      margin: auto;
      padding: 5px 5px 12px;
      text-transform: capitalize;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: rgb(156, 156, 156);
    }
    li > .nav-link > .active {
      background-color: none !important;
      margin: auto;
      padding: 5px 5px 12px;
      text-transform: capitalize;
      font-weight: bolder !important;
      font-family: Rubik;
      font-style: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: rgb(156, 156, 156);
    }
    li > button {
      font-weight: bold !important;
    }
    ${'' /* > ul {
      width: 67.1%;
      padding: 20px 20px 1px 20px;
      margin-top: 25px;
      background-color: white;
      border-radius: 6px;
      border: 1px solid red;
      display: flex;
      -webkit-box-pack: center;
      justify-content: space-around;
    } */}
`;

const CardWrapper = styled.div`
display: flex;
max-width: 1246px;
margin: auto;
>div:first-child {
  width: 100%;

}
>div:last-child {
  width: 100%;
  
}
`;

const DropDownContainer = styled.div`
  min-width: 177px;
  width: auto;

  @media (max-width: 500px) {
    margin-top: 15px;
  }
`;

const Recipe = styled.div`
 
`;

const Heading = styled.div`
 width: 100%;
 margin-top:20px;
 margin-bottom: 15px;
 text-transform: capitalize;
 font-family: Rubik-Medium;
 font-style: normal;
 font-size: 18px;
 line-height: 24px;
 color: #0D4270;
`;


const RecommendedContainer = styled.div`
width:1248px;
.row{
  display:flex;
  justify-content:center;
  width:100%;
  margin:0;
  .col-1{
    background:white;
    border-radius: 6px 0 0 6px;
    width: ${({width}) => width ? "534px" : '496px'};
    padding: ${({width}) => width && '0px'};
    .type{
      display:flex;
      margin:20px;

    .type-text{
      border-radius: 6px;
      background: rgba(0, 92, 135, 0.60);
      color:#FFF;
      font-family: Rubik-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; 
      padding:5px 15px;
      text-transform:capitalize;
     }
    }

    .details{
      display:flex;
      flex-direction:column;
      justify-content:center;
      height:100%;
      margin-left: ${({width}) => width ? "20px" : '44px'};
      .title{
        color:  #005C87;
        text-transform: capitalize;
        font-family: Rubik-Medium;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .challengeType{
        width:100%;
        display:flex;
        margin-top:25px;
        .icon{
          height:24px;
          width:auto;
          display:flex;
          >img{
            width:100%;
            height:100%;
          }
        }
        .type{
          font-family: Rubik;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: #005c87;
          display:flex;
          align-items:center;
          margin:0;
          padding-left:10px;
        }
      }
      .description{
        color:  #005C87;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 28px 0px;
        -webkit-line-clamp: 8;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        overflow: hidden;
      }
      .titleWrapper{
        color:  #005C87;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 12px 0px;
        -webkit-line-clamp: 8;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        overflow: hidden;
        >div{
          color: #005C87;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          width:100%;
          margin: 16px 0;
          list-style-type: disc;
          display:flex;
          .bullet{
            font-size: 36px;
            color: #005C87;
            padding-right:8px;
          }
        }
      }
      .moving-btn{
        align-items:center;
        flex-shrink: 0;
        display:flex;
        button{
          width: 352px;
          height: 48px;
          border-radius: 4px;
          background: #007AB1;
          ${'' /* box-shadow: 0px 8px 24px 0px rgba(0, 92, 135, 0.50); */}
          color: #FFF;
          text-align: center;
          font-family: Rubik-Medium;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          border:none;
          flex-shrink: 0;
        }
        .fav-icon{
          border-radius: 3px;
          background:  rgba(0, 92, 135, 0.05);
          padding:13px;
          margin-left:15px;
        }
      }
    }
  }

  .col-2{
    background:${({background})=> background ? `linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 93.33%),url(${ImageUrl}/${background})` : 'red'};
    background-size : cover;
    background-repeat : no-repeat;
    background-size: 100% 100%;
    width: ${({width}) => width ? "714px" : '750px'};
    height:450px;
    border-radius: 0px 6px 6px 0px;
    .pick{

      margin-right:16px;

      .title{
      color: #005C87;
      text-align: center;
      font-family: Rubik-Medium;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    }
  }

  .imageContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  .imageWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10px;
  }
}
`;

const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  text-align: center;
`;

const QuizName = styled.h1`
  color: var(--Navy_blue, #005C87);
  text-align: center;
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; 
`;

const StyledBox = styled.div`
  width: 1248px;
  height: auto
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--White, #FFF);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding:24px;
`;

const TimerCard = styled.div`
  display: flex;
  height: 48px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--navy-blue-10, rgba(0, 92, 135, 0.10));
`;

const TimeLimit = styled.div`
display: flex;
align-items:center;
color: var(--Navy_blue, #005C87);
text-align: center;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
`;

const QuestionsCard = styled.div`
  display: flex;
  width: 1200px;
  padding: 12px 12px 12px 12px;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  border-radius: 6px;
  background: rgba(118, 171, 120, 0.15);
  margin-top: 12px; 

  .questionNumber {
    display: inline-flex;
    height: 40px;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    font-size: 14px;
    border-radius: 24px;
    background: var(--nutrition, #76AB78);
    color:#ffffff;
  }

  .questions {
    color: var(--navy_blue, #005C87);
    text-align: center; 
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    margin-top: 16px; 
  }
`;

const OptionsList = styled.div`
   display: flex;
   height: 60px;
   padding: 4px 12px;
   align-items: center;
   gap: 12px;
   align-self: stretch;
   border-radius: 4px;
   border: 1px solid var(--navy-30, rgba(0, 92, 135, 0.30));
   background: #FFF;
   margin-top:20px;
   cursor: pointer;

   .options{
    color: var(--Navy_blue, #005C87);
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    text-transform: capitalize;
   }

   &.selected {
    background-color: var(--navy-10, rgba(0, 92, 135, 0.10));
  }
`;


export const FlexContainer = styled.div`
  margin-top: 20px;
`;

export const FlexDiv = styled.div`
  display: flex;
  height: 60px;
  padding: 4px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid rgba(0, 92, 135, 0.30);
  ${'' /* background: rgba(118, 171, 120, 0.15); */}
  background: ${({bgColor}) => bgColor ? bgColor : 'rgba(118, 171, 120, 0.15)'}; 
  justify-content: space-between;
  margin-top: 10px;
`;

export const LeftText = styled.span`
  color: #005C87;
`;

export const RightText = styled.span`
  color: #005C87;
`;


export const LeaderboardContainer = styled.div`
  width: 100%;
  padding: 0;
  .title{
    color: #005C87;
    font-family: Rubik-Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    display:flex;
    flex-wrap:wrap;
    padding-bottom:20px;
   }

   .paginationLeaderboard {
    margin-top: 40px;
    width: 100%;
    display: flex;
    float: left;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const LeaderboardHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-family: "Rubik";
  align-items: center;
  margin: auto;
  padding: 10px;
  color: #005c87;
  border-radius: 6px;
  border: 1px solid rgba(0, 92, 135, 0.3);
  background: #fff;
  width: 752px;
  height: 40px;
`;

export const LeaderboardRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 752px;
  font-size: 16px;
  font-family: "Rubik";
  height: 80px;
  border-radius: 6px;
  border: 1px solid rgba(0, 92, 135, 0.3);
  background: ${(props) => props.backgroundColor || "#fff"};
  margin-top: 20px;
  color: ${(props) => props.textColor || "#005c87"};
  align-items: center;
  padding: 10px;
`;

export const UserColumn = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
`;

export const UserName = styled.div`
  font-family: "Rubik-medium";
  font-size: 18px;
`;

export const RankColumn = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const RankText = styled.span`
  font-size: smaller;
  position: relative;
  top: -1px;
`;

export const AnswerColumn = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const TimeColumn = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
`;





export { Layout, HeaderContainer, QuizContainer,QuizName, TimeLimit,TimerCard,OptionsList,QuestionsCard,StyledBox, CustomTabs, DropDownContainer, CardWrapper, Recipe, Heading,RecommendedContainer };